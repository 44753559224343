:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

/*@media (prefers-color-scheme: dark) {*/
/*  :root {*/
/*    --foreground-rgb: 0, 0, 0;*/
/*    --background-start-rgb: 255, 255, 255;*/
/*    --background-end-rgb: 255, 255, 255;*/

/*    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));*/
/*    --secondary-glow: linear-gradient(*/
/*      to bottom right,*/
/*      rgba(1, 65, 255, 0),*/
/*      rgba(1, 65, 255, 0),*/
/*      rgba(1, 65, 255, 0.3)*/
/*    );*/

/*    --tile-start-rgb: 2, 13, 46;*/
/*    --tile-end-rgb: 2, 5, 19;*/
/*    --tile-border: conic-gradient(*/
/*      #ffffff80,*/
/*      #ffffff40,*/
/*      #ffffff30,*/
/*      #ffffff20,*/
/*      #ffffff10,*/
/*      #ffffff10,*/
/*      #ffffff80*/
/*    );*/

/*    --callout-rgb: 20, 20, 20;*/
/*    --callout-border-rgb: 108, 108, 108;*/
/*    --card-rgb: 100, 100, 100;*/
/*    --card-border-rgb: 200, 200, 200;*/
/*  }*/
/*}*/

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.loading_container {
  height: 100vh;
  background-color: #000a;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-picture {
  background-image: url("./images/background/login-register.jpg");
}

body {
  margin: 0;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/
/*Theme Colors*/
/**
 * Table Of Content
 *
 * 	1. Color system
 *	2. Options
 *	3. Body
 *	4. Typography
 *	5. Breadcrumbs
 *	6. Cards
 *	7. Dropdowns
 *	8. Buttons
 *	9. Typography
 *	10. Progress bars
 *	11. Tables
 *	12. Forms
 *	14. Component
 */
/*******************
Login register and recover password Page
******************/
.login-register {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  padding: 10% 0;
  position: fixed;
}

.login-box {
  width: 400px;
  margin: 0 auto;
}
.login-box .footer {
  width: 100%;
  left: 0px;
  right: 0px;
}
.login-box .social {
  display: block;
  margin-bottom: 30px;
}

#recoverform {
  display: none;
}

.login-sidebar {
  padding: 0px;
  margin-top: 0px;
}
.login-sidebar .login-box {
  right: 0px;
  position: absolute;
  height: 100%;
}

.AppData {
  font-size: 10px;
  text-align: center;
}

.o-income .c3 line,
.o-income .c3 path {
  stroke: #e9ecef;
}

aside {
  height: 100vh;
}

aside .aside-logo {
  height: auto;
}

aside .aside-logo .company-information img {
  width: 80%;
  margin: auto;
  display: block;
}

aside .sidebar-nav {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 90vh;
}

.card {
  margin-top: 20px;
  padding: 20px;
}

.card .card-title {
  font-size: 24px;
  font-weight: bold;
}

.secureBody {
  height: 100vh;
  max-height: 100vh;
}

.company-selector {
  margin: 24px 5px 24px 5px;
}

.user-profile {
  padding: 0 15px;
  display: flex;
  flex-flow: column nowrap;
  font-size: 16px;
  font-weight: 400;
}

.user-profile > span {
  font-size: 20px;
  font-weight: 600;
}

.button-in-form-group {
  margin-top: 30px;
}

.loading-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #0007;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000 !important;
}

.spinner-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.spinner-container p {
  margin-top: 10px;
  color: #fff;
  font-size: 18px;
}

.input-sku {
  font-size: 32px;
  padding: 10px 15px;
  width: 100%;
}

.search-icon {
  font-size: 32px;
}

.order-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.total-number {
  text-align: right;
  font-size: 36px;
  font-weight: bold;
}

.product-searcher td {
  font-size: 16px;
}

.button-confirm-order {
  width: 100%;
  padding: 30px;
}

.order-product-event {
  border-radius: 10px;
  padding: 20px;
  background: #eee;
  margin-bottom: 15px;
}

.order-product-event p {
  margin-bottom: 0;
  margin-top: 0;
}

.badge-version {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image-container {
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.badge-raca {
  padding: 25px;
  box-shadow: #ccc 1px 1px 5px;
  border-radius: 10px;
}

.line-chart-container {
  padding: 25px;
  box-shadow: #ccc 1px 1px 5px;
  border-radius: 10px;
}

.line-chart {
  width: 100%;
}

.btn-onpole {
  color: #fff !important;
  background-color: #a6041a !important;
  border-color: #a6041a !important;
}

.btn-close {
  border: none;
  width: 20px;
  height: 20px;
  background: transparent;
}

.btn-close::after {
  content: "X";
}

::placeholder {
  color: #ccc !important;
  opacity: 1;
}

.time-day-container {
  display: flex;
  margin-top: 10px;
}

.time-badge-container {
  margin-left: 10px;
}

.time-badge-container input{
  width: 45px !important;
}

.time-badge {
  color: #fff;
  margin-right: 5px;
}

.iframe-container {
}

.reports-image {
  width: 100%;
}

.time-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.time-selector {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.time-selector-container {
  display: flex;
  align-items: center;
}

.time-selector-container select {
  width: 70px;
  padding: 5px;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.selected a,
.selected a i {
  color: #a6051a !important;
}
.selected a {
  border: 1px solid #a6051a !important;
  border-radius: 12px !important;
}
.icon-onpole {
  margin-right: 10px;
}

.sidebar-nav ul li a:hover i {
  color: #8d97ad !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
  border-radius: 1.25em !important;
}

.text-gray{
  color: #828788 !important;
}

.pointer{
  cursor: pointer;
}

.text-md{
  font-size: 1rem !important;
}

.text-lg{
  font-size: 1.5rem !important;
}

.rounded-full{
  border-radius: 100% !important;
}

.btn-ligth{
  background-color: transparent !important;
  border-color: unset !important;
}

.btn-ligth:hover{
  background-color: #efefef !important;
}

.icon-green{
  color: #00C292;
  font-weight: bolder !important;
}

.MuiSwitch-thumb{
  color: white !important;
}

.css-1yjjitx-MuiSwitch-track{
  height: 25px !important;
  width: 45px !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track{
  background-color: #A6051A !important;
  opacity: 1 !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
  top: 5px !important;
  left: 6px !important;
}

.css-julti5-MuiSwitch-root{
  width: 70px !important;
  height: 50px !important;
}

.css-1yjjitx-MuiSwitch-track{
  border-radius: 12px !important;
}

.text-red{
  color: #A6051A;
}

.text-green{
  color: #00C292;
}

.text-center{
  text-align: center !important;
}

.center{
  align-items: center !important;
}

.rounded-md{
  border-radius: 1rem !important;
}
