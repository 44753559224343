.mt-20 {
	margin-top: 20px !important;
}
input::placeholder {
	font-size: 15px;
}
.error-input {
	color: #A6051A !important;
}
.input-error-focus {
	border: 1px solid #A6051A !important;
	margin-bottom: 10px !important;
}
.btn-reset {
	background-color: #A6051A !important;
}
.btn-reset:hover {
	background-color: #a6051af1 !important;
}
.py-52 {
	padding-top: 52px !important;
	padding-bottom: 52px !important;
}
.px-57 {
	padding-left: 57px !important;
	padding-right: 57px !important;
}
.box-shadow-none {
	box-shadow: none !important;
}
.outline-none {
	outline: none !important;
}
.rounded-8 {
	border-radius: 8px !important;
}
.my-14 {
	margin-top: 14px !important;
	margin-bottom: 14px !important;
}
.mb-14 {
	margin-bottom: 14px !important;
}
.mt-14 {
	margin-top: 14px !important;
}