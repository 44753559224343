tr, td {
    padding: 8px !important;
}
table {
    border-collapse: collapse !important;
    border-spacing: 0 !important;
}
.table-rounded {
    border-radius: 12px; /* Ajusta el valor según tus preferencias */
    overflow: hidden; /* Asegura que las esquinas recortadas se respeten */
}
.table-rounded th:first-child {
    border-top-left-radius: 12px;
    border-top: 1px solid #e0e0e0 !important;
}
.rounded-t-10 {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border-top: 1px solid #e0e0e0 !important;
}
.table-rounded th:last-child {
    border-top-right-radius: 12px;
}

.table-rounded tr:last-child td:first-child {
    border-bottom-left-radius: 12px;
}

.table-rounded tr:last-child td:last-child {
    border-bottom-right-radius: 12px;
}
.rounded-10 {
    border-radius: 12px !important;
}
.text-24 {
    font-size: 24px !important;
}
.font-medium {
    font-weight: 500 !important;
}
.font-semibold {
    font-weight: 600 !important;
}
.font-bold {
    font-weight: 700 !important;
}
.relative {
    position: relative !important;
    left: -40px !important;
    z-index: 10 !important;
}
.bg-transparent {
    background-color: transparent !important;
    color: #a6051a !important;
    border: none !important;
}