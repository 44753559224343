
.password-recovery .logo-container {
	width: 100px;
	height: 100px;
	background: var(--primary-10);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.password-recovery .logo-container img {
	width: 100%;
	height: auto;
}
.password-recovery.form-container a {
	padding: 50px 0 50px 0;
}
.password-recovery .subtitle {
	padding: 0 100px;
	color: rgba(130, 135, 136, 1);
	text-align: center;
}
.w-33 {
	width: 33% !important;
}
.w-66 {
	width: 66% !important;
}
.mt-20 {
	margin-top: 20px !important;
}
input::placeholder {
	font-size: 15px;
}
.error-input {
	color: #A6051A !important;
}
.input-error-focus {
	border: 1px solid #A6051A !important;
	margin-bottom: 10px !important;
}
.btn-login {
	background-color: #A6051A !important;
}
.btn-login:hover {
	background-color: #a6051af1 !important;
}
.py-52 {
	padding-top: 52px !important;
	padding-bottom: 52px !important;
}
.px-57 {
	padding-left: 57px !important;
	padding-right: 57px !important;
}
.box-shadow-none {
	box-shadow: none !important;
}
.outline-none {
	outline: none !important;
}
.rounded-8 {
	border-radius: 8px !important;
}
.my-14 {
	margin-top: 14px !important;
	margin-bottom: 14px !important;
}
.mb-14 {
	margin-bottom: 14px !important;
}
.mt-14 {
	margin-top: 14px !important;
}